// eslint-disable-next-line @typescript-eslint/no-var-requires,import/no-unresolved
const CodeMirror = require('codemirror');

/**
 * Include more modes to support more syntax highlighting. They are built in, simply have a look
 * in the codemirror/mode directory.
 */
require('codemirror/mode/htmlmixed/htmlmixed');

document.addEventListener('DOMContentLoaded', () => {
  $('.codemirror-text-editor').each((i, textarea) => {
    let language = $(textarea).data('language') || 'html';

    // Display all modes available that are loaded:
    // console.info(CodeMirror.modes, CodeMirror.mimeModes);

    if (language === 'html') language = 'htmlmixed';

    CodeMirror.fromTextArea(textarea, {
      lineNumbers: true,
      mode: 'htmlmixed',
      iframeClass: 'form-control',
    });
  });
});
